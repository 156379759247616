@import '~formiojs/dist/formio.full.css';
@import '~boxicons/css/boxicons.min.css';
@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.formio-form > div > nav > ul.pagination {
  flex-flow: row wrap !important  ;
  justify-content: center !important;
}

.formio-form > div > nav > ul.pagination .page-item.active .page-link {
  background-color: #c22136 !important;
}

.formio-form > div > nav > ul.pagination .page-link {
  color: #fff !important;
}

.form-check-input{
  width: 1.2em !important;
  height: 1.2em !important;
  margin-top: 0.15em !important;
  margin-right: 0.4em !important;
}

.form-check-input:checked {
  background-color: #c22136 !important;
  border-color: #c22136 !important;
  box-shadow: #e5dbdb 0px 1px 4px, #e5dbdb 0px 0px 0px 3px;
}

.formio-form .list-inline {
  display: flex;
  justify-content: center;
  margin-top: 15px !important;
}

.formio-component-submit .btn-primary{
  background-color: #da2d31;
  border: none;
  
  &:hover {
    background-color: #c22136;
    color: white;
  }
}
